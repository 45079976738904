<template>
  <Top />
  <div class="panel">
    <div class="panel-heading">
      <div class="level is-mobile">
        <div class="level-left">Arrangements</div>
        <div class="level-right">
          <!-- <span>🔍</span> -->
          <button
            v-if="store.canEdit(props.group)"
            class="button is-small"
            @click="add"
          >
            Add
          </button>
        </div>
      </div>
    </div>
    <ArrangementLine
      v-for="[id, arrangement] in arrangements.arrangements"
      :id="id"
      :key="id"
      :group="group"
      :arrangement="arrangement"
      :persons="persons"
    />
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { v4 as uuidv4 } from "uuid";
import { useRehorseStore } from "../store";
import Top from "../components/Top.vue";
import ArrangementLine from "../components/ArrangementLine.vue";

const props = defineProps<{
  group: string;
}>();

const store = useRehorseStore();
const router = useRouter();
const group = store.getGroup(props.group);
const arrangements = store.getArrangements(props.group);
const persons = store.getPersons(props.group);

async function add() {
  const id = uuidv4();
  await router.push({
    name: "arrangement",
    params: { group: props.group, arrangement: id },
  });
}
</script>
