import { readonly, Ref, ref, watch } from "vue";
import { CookieChangeOptions } from "universal-cookie";
import UniversalCookie from "universal-cookie";
import { useCookies } from "@vueuse/integrations/useCookies";
import jwt_decode from "jwt-decode";
import { online } from "./online";
import { io as cookie_io, User } from "../shared/cookie";
import { logError } from "./errors";

const cookieName = "auth";
const cookies = useCookies(
  [cookieName],
  undefined,
  // Use an instance of universalCookie that remove cookies with the option
  // sameSite: "strict" so that that the browser does not complain about that
  // option missing.
  new UniversalCookie(null, { sameSite: "strict" }),
);

function getUserFromCookie(): User | undefined {
  const auth_cookie: unknown = cookies.get(cookieName);
  if (!auth_cookie || auth_cookie === "undefined") {
    return;
  }
  // Check the expiration of the cookie.
  // To make sending PUT or POST with an expired cookie, require that
  // the session is valid for at least another day.
  // It is hard to come up with a nice ui that refreshes the cookie before
  // sending a PUT or POST. Requiring the cookie to be valid for another
  // day might help to prevent failing PUT or POST
  const result = cookie_io.decode(auth_cookie);
  if (result.isErr || result.value.token === undefined) {
    logError("Could not parse cookie", result.value, auth_cookie);
    return;
  }
  // when working offline, ignore the expiration time of the cookie
  if (!online.value) {
    return result.value;
  }
  const decoded = jwt_decode(result.value.token);
  if (decoded !== null && typeof decoded === "object" && "exp" in decoded) {
    const exp = decoded.exp;
    const day = 24 * 3600 * 1000;
    if (typeof exp === "number" && exp * 1000 > Date.now() + day) {
      // token will expire after more than a day and is safe to use
      return result.value;
    }
    // cookie has expired
  }
  return;
}

function logout() {
  try {
    if (cookies.get(cookieName)) {
      cookies.remove(cookieName);
    }
  } catch (e) {
    logError(e);
  }
  mutableUser.value = undefined;
}

function setUser() {
  let newUser;
  try {
    newUser = getUserFromCookie();
  } catch (e) {
    logError(e);
  }
  if (newUser === undefined) {
    logout();
  }
  mutableUser.value = newUser;
}

const mutableUser: Ref<User | undefined> = ref();
const user = readonly(mutableUser);
setUser();
watch(user, (newUser, oldUser) => {
  console.debug(`User changed: ${oldUser?.name} -> ${newUser?.name}`);
});

let inListener = false;
function cookieListener(e: CookieChangeOptions) {
  if (inListener) {
    // there is recursion going on, get out
    return;
  }
  if (e.name === cookieName) {
    console.debug(`Cookie '${e.name}' changed.`, e.value);
    inListener = true;
    try {
      setUser();
    } finally {
      inListener = false;
    }
  }
}
cookies.addChangeListener(cookieListener);

export { cookies, user, logout };
