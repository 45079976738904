<template>
  <Top />
  <div v-if="user" class="panel">
    <div class="panel-heading">
      <div class="level is-mobile">
        <div class="level-left">
          You are logged in as<br />
          {{ user.name }} ({{ user.username }})
        </div>
        <div class="level-right">
          <button
            class="button is-small"
            :disabled="!online"
            @click="local_logout"
          >
            Log out
          </button>
        </div>
      </div>
    </div>
    <div v-if="adminGroups.length === memberGroups.length" class="column">
      <span>You are member and admin of:</span>
      <router-link
        v-for="group of adminGroups"
        :key="group.groupname"
        class="panel-block"
        :to="{ name: 'group-view', params: { group: group.groupname } }"
        >{{ group.name }}</router-link
      >
    </div>
    <div v-if="memberGroups.length > adminGroups.length" class="column">
      <span>You are member of:</span>
      <router-link
        v-for="group of memberGroups"
        :key="group.groupname"
        :to="{ name: 'group-view', params: { group: group.groupname } }"
        class="panel-block"
        >{{ group.name }}</router-link
      >
    </div>
    <div class="panel-block">
      <router-link :to="{ name: 'app-info' }" class="button"
        >App info</router-link
      >
    </div>
  </div>
  <div v-else class="panel">
    <div class="panel-heading">
      <div class="level is-mobile">
        <div class="level-left">Login</div>
        <div class="level-right">
          <form id="login" onsubmit="return false">
            <AsyncButton
              class="is-small"
              :disabled="!online || !username || !password"
              :handler="login"
              >Login</AsyncButton
            >
          </form>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="field">
        <label class="label">Username:</label>
        <div class="control">
          <input
            v-model="username"
            form="login"
            class="input"
            type="text"
            name="username"
            placeholder="Username"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">Password:</label>
        <div class="control">
          <input
            v-model="password"
            form="login"
            class="input"
            type="password"
            name="password"
            placeholder="Password"
          />
        </div>
      </div>
    </div>
    <div>
      <router-link :to="{ name: 'reset-password' }">reset password</router-link>
    </div>
  </div>
  <div v-if="error" class="panel-block">{{ error }}</div>
</template>
<script setup lang="ts">
import { computed, ref, Ref } from "vue";
import { useRehorseStore } from "../store";
import { useRouter } from "vue-router";
import Top from "../components/Top.vue";
import AsyncButton from "../components/AsyncButton.vue";
import { Group } from "../../shared/group";
import { online } from "../online";
import { user, logout } from "../user";
import { logError } from "../errors";

const store = useRehorseStore();
const username = ref("");
const password = ref("");
const error = ref("");
const router = useRouter();

const adminGroups: Ref<Group[]> = computed(() => {
  if (!store.groups.isReady) {
    return [];
  }
  const name = user.value?.username;
  return store.groups.data.filter((g) => g.admins.some((u) => u === name));
});
const memberGroups = computed(() => {
  if (!store.groups.isReady) {
    return [];
  }
  const name = user.value?.username;
  return store.groups.data.filter((g) => g.members.some((u) => u === name));
});

async function login() {
  error.value = "";
  try {
    const response = await fetch("login", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        username: username.value,
        password: password.value,
      }),
    });
    if (response.ok) {
      router.go(-1);
    } else {
      error.value = response.statusText + " " + (await response.text());
    }
  } catch (e) {
    logError(e);
    if (e instanceof Error) {
      error.value = e.toString();
    }
  }
}

function local_logout() {
  logout();
}
</script>
