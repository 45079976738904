<template>
  <Multiselect
    v-model="chosen"
    :options="options"
    track-by="value"
    :multiple="true"
    :taggable="false"
    label="label"
    @update:model-value="change"
  ></Multiselect>
</template>
<script setup lang="ts">
import { computed, ref, Ref } from "vue";
import Multiselect from "vue-multiselect";
import { UserId } from "../../shared/ids";

const props = defineProps<{
  members: UserId[];
  initial: UserId[];
  keep?: UserId[];
  names: Record<UserId, string>;
}>();
const emit = defineEmits<(e: "change", items: UserId[]) => void>();

interface Item {
  value: UserId;
  label: string;
}

function item(value: UserId): Item {
  return {
    value,
    label: props.names[value] ?? value,
  };
}

const options = computed((): Item[] => {
  return props.members.map(item);
});
const chosen: Ref<Item[]> = ref(props.initial.map(item));

function change() {
  // make sure the of the value in `keep` are removed
  for (const k of props.keep ?? []) {
    if (!chosen.value.find((c) => c.value === k)) {
      chosen.value.push(item(k));
    }
  }
  emit(
    "change",
    chosen.value.map((i) => i.value),
  );
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
