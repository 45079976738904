<template>
  <div>{{ props.part.name }}</div>
  <div class="columns is-desktop is-gapless">
    <div class="column">
      <div class="select clipped is-fullwidth">
        <select v-model="currentPdf" @focus="focusStart" @change="changePdf">
          <option value=""></option>
          <option
            v-for="(pdf, index) in props.pdfs"
            :key="pdf.sha256"
            :value="index"
          >
            {{ pdf.filename }}
          </option>
          <option value="-">𝑡𝑎𝑐𝑒𝑡</option>
        </select>
      </div>
    </div>
    <div v-if="currentPdf !== '' && currentPdf !== '-'" class="column">
      <div class="columns is-gapless">
        <div class="column">
          <div class="select is-fullwidth">
            <select
              v-model="rotation"
              @focus="focusStart"
              @change="changeRotation"
            >
              <option value="0">0°</option>
              <option value="90">90°</option>
              <option value="180">180°</option>
              <option value="270">270°</option>
            </select>
          </div>
        </div>
        <div class="column">
          <input
            v-model="startPage"
            class="input"
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
            :min="1"
            :max="maxPage"
            @focus="focusStart"
            @change="changeStart"
          />
        </div>
        <div class="column">
          <input
            v-model="endPage"
            class="input column"
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
            :max="maxPage"
            @focus="focusEnd"
            @change="changeEnd"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, Ref } from "vue";
import type { Pdf } from "../../shared/rehorse";
import type { Part } from "../../shared/group";
import type { PartId } from "../../shared/ids";
import type { ArrangementPart } from "../../shared/rehorse";

const props = defineProps<{
  part: Part;
  arrangementPart: ArrangementPart | null | undefined;
  partId: PartId;
  pdfs: Pdf[];
}>();

const emit = defineEmits<{
  (e: "changePage", pdf: Pdf | undefined, page: number, rotation: number): void;
  (e: "change", partId: PartId, part: ArrangementPart | null | undefined): void;
}>();

const currentPdf = ref("");
const rotation: Ref<number> = ref(props.arrangementPart?.rotation ?? 0);
if (props.arrangementPart === null) {
  currentPdf.value = "-";
} else if (props.arrangementPart) {
  currentPdf.value = props.pdfs
    .findIndex((p) => p.sha256 === props.arrangementPart?.pdf)
    .toString();
}
const startPage = ref(props.arrangementPart?.start ?? 1);
const endPage = ref(props.arrangementPart?.end ?? 1);

const maxPage = computed(
  (): number => props.pdfs[+currentPdf.value]?.pages ?? 1,
);
function clampStart() {
  const max = maxPage.value;
  if (startPage.value > max) {
    startPage.value = max;
  }
}
function changePdf() {
  clampStart();
  // if the pdf is short, set the endPage to the last page if it
  // was not set yet
  const max = maxPage.value;
  if (startPage.value === 1 && endPage.value === 1 && max < 10) {
    endPage.value = max;
  }
  emitPdf(startPage.value);
  emitChange();
}
function changeRotation() {
  emitPdf(startPage.value);
  emitChange();
}
function changeStart() {
  focusStart();
  emitChange();
}
function focusStart() {
  if (startPage.value < 1) {
    startPage.value = 1;
  }
  clampStart();
  if (endPage.value < startPage.value) {
    endPage.value = startPage.value;
  }
  emitPdf(startPage.value);
}
function changeEnd() {
  focusEnd();
  emitChange();
}
function focusEnd() {
  if (endPage.value < 1) {
    endPage.value = 1;
  }
  const max = maxPage.value;
  if (endPage.value > max) {
    endPage.value = max;
  }
  if (endPage.value < startPage.value) {
    endPage.value = startPage.value;
  }
  emitPdf(endPage.value);
}
function emitPdf(page: number) {
  emit("changePage", props.pdfs[+currentPdf.value], page, +rotation.value);
}
function emitChange() {
  let part;
  const pdf = props.pdfs[+currentPdf.value];
  if (currentPdf.value === "-") {
    part = null;
  } else if (currentPdf.value !== "" && pdf) {
    part = {
      pdf: pdf.sha256,
      start: startPage.value,
      end: endPage.value,
      rotation: +rotation.value,
    };
  }
  emit("change", props.partId, part);
}
</script>
<style>
@media (max-width: 768px) {
  div > div.select.clipped > select {
    padding-inline-end: 0;
    direction: rtl;
  }
}
</style>
