<template>
  <Top />
  <GroupEdit
    v-if="editing && data"
    :group="data.group"
    :names="names"
    @cancel="cancel"
    @save="save"
  />
  <div v-else-if="adding && data" class="panel">
    <AddMembers :group="data.group" @cancel="cancel" @done="added" />
  </div>
  <div v-else class="panel">
    <div class="panel-heading">
      <div class="level is-mobile">
        <div class="level-left">{{ data?.group.name }}</div>
        <div
          v-if="online && store.canEdit(props.group)"
          class="level-right level is-mobile"
        >
          <button class="button is-small" @click="edit">Edit</button>
          <button class="button is-small" @click="add">Add members</button>
        </div>
      </div>
    </div>
    <div>
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>role</th>
            <th>player</th>
            <th>backup</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="part of data?.group.parts.values()" :key="part.name">
            <td>{{ part.name }}</td>
            <td>{{ join(part.members) }}</td>
            <td>{{ join(part.backups) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Admins</td>
            <td colspan="2">
              {{ join(data?.group.admins) }}
            </td>
          </tr>
          <tr v-if="unassignedMembers.length > 0">
            <td>Overig</td>
            <td colspan="2">
              {{ join(unassignedMembers) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script setup lang="ts">
import Top from "../components/Top.vue";
import GroupEdit from "../components/GroupEdit.vue";
import AddMembers from "../components/AddMembers.vue";
import { getGroupData, useRehorseStore } from "../store";
import { Ref, ref } from "vue";
import { Group } from "../../shared/group";
import { online } from "../online";
import { UserId } from "../../shared/ids";
import { computed } from "vue";

const props = defineProps<{
  group: string;
}>();

const editing = ref(false);
const adding = ref(false);
const store = useRehorseStore();
const data = getGroupData(props.group);
const names: Ref<Record<string, string>> = ref({});
async function loadNames() {
  const response = await fetch("api/names");
  const json: unknown = await response.json();
  const newNames: Record<string, string> = {};
  if (json instanceof Object) {
    let key: keyof typeof json;
    for (key in json) {
      const value: unknown = json[key];
      if (typeof value === "string") {
        newNames[key] = value;
      }
    }
  }
  names.value = newNames;
}
void loadNames();
function edit() {
  editing.value = true;
}
function add() {
  adding.value = true;
}
function added() {
  void loadNames();
  editing.value = false;
  adding.value = false;
}
function cancel() {
  editing.value = false;
  adding.value = false;
}
function name(username: string): string {
  return names.value[username] ?? username;
}
function join(users: UserId[] | undefined): string {
  return users?.map(name).join(", ") ?? "";
}
const unassignedMembers = computed((): UserId[] => {
  const group = data.value?.group;
  return (
    group?.members.filter((m) => {
      for (const part of group.parts.values()) {
        if (part.members.includes(m)) {
          return false;
        }
      }
      return true;
    }) ?? []
  );
});
async function save(group: Group) {
  editing.value = false;
  await store.saveGroup(props.group, group);
}
</script>
