import { ajvToJsonSchema, create_io } from "./ajv.js";
import { Type, StaticDecode } from "@sinclair/typebox";
import { lcNameSchema, userIdSchema } from "./ids.js";

const userSchema = Type.Object({
  uid: Type.Integer(),
  name: Type.String(),
  username: userIdSchema,
  email: Type.String({ format: "email" }),
  token: Type.Optional(Type.String()),
});
const userAjvSchema = {
  ...userSchema,
  $defs: { lcname: lcNameSchema },
};

export type User = StaticDecode<typeof userSchema>;

export const cookieJsonSchema = () =>
  ajvToJsonSchema(
    {
      $schema: "http://json-schema.org/draft-07/schema#",
      $id: "https://rehorse.vandenoever.info/cookie.schema.json",
      title: "Cookie",
      description: "The cookie of Rehorse",
    },
    userAjvSchema,
  );

export const io = create_io(userAjvSchema, [lcNameSchema]);
