import { Piece, Playlist, io } from "../../shared/rehorse";
import { cachedLoad } from "./cached-loader";
import {
  ConcertId,
  LocationId,
  PieceId,
  PlaylistId,
  RehearsalId,
  Sha256,
} from "../../shared/ids";
import { Concert, Rehearsal, Location } from "../../shared/agenda";
import { Persons } from "../stores/people";
import { Arrangements } from "../stores/arrangements";
import { deleteFromCache, hashValue } from "../cachedb";
import { Group } from "../../shared/group";
import { groups } from "./groups";

export interface AgendaData {
  concerts: Map<ConcertId, Concert>;
  rehearsals: Map<RehearsalId, Rehearsal>;
  locations: Map<LocationId, Location>;
}

export interface RehorseData {
  group: Group;
  sha256: Sha256;
  agenda: AgendaData;
  pieces: Map<PieceId, Piece>;
  playlists: Map<PlaylistId, Playlist>;
  persons: Persons;
  arrangements: Arrangements;
}

const rL = async (
  uri: string,
  callback: (t: RehorseData) => void,
): Promise<RehorseData> => {
  return await cachedLoad(
    { uri, checkForUpdate: true, updateCallback: callback },
    async (blob) => {
      if (!groups.value.isReady) {
        throw new Error("Groups are not yet loaded.");
      }
      const groupname = uri.split("/")[1];
      const group = groups.value.data.find((g) => g.groupname === groupname);
      if (!group) {
        throw new Error(`Group '${groupname}' does not exist.`);
      }
      const jsonResult = io.decode(JSON.parse(await blob.text()));
      if (jsonResult.isErr) {
        await deleteFromCache(uri);
        throw jsonResult.value;
      }
      const sha256 = await hashValue(blob);
      const json = jsonResult.value;
      return {
        group,
        sha256,
        agenda: json.agenda,
        pieces: json.pieces,
        playlists: json.playlists,
        persons: new Persons(json.persons),
        arrangements: new Arrangements(json.arrangements),
      };
    },
  );
};

const rehorseLoaderOptions = { getter: rL };

export { rehorseLoaderOptions };
