<template>
  <template v-if="data">
    <div
      v-for="item in items"
      :key="item.arrangement?.title ?? item.name ?? ''"
    >
      <ArrangementLine
        v-if="item.id && item.arrangement"
        :id="item.id"
        :group="data.group"
        :arrangement="item.arrangement"
      />
      <p v-else class="panel-block has-text-grey">
        <span class="panel-icon"></span>
        <em>{{ item.name }}</em>
      </p>
    </div>
  </template>
</template>

<script setup lang="ts">
import { getGroupData } from "../store";
import type { ArrangementId, PlaylistId } from "../../shared/ids";
import ArrangementLine from "../components/ArrangementLine.vue";
import { Arrangement } from "../../shared/rehorse";
import { computed } from "vue";

const props = defineProps<{
  group: string;
  playlist: PlaylistId;
}>();

interface Item {
  name?: string;
  id?: ArrangementId;
  arrangement?: Arrangement;
}
const data = getGroupData(props.group);
const items = computed(() => {
  const playlist = data.value?.playlists.get(props.playlist);
  const is: Item[] = [];
  playlist?.items.forEach((i) => {
    if (i.name) {
      is.push({ name: i.name });
    } else if (i.arrangement) {
      const a = data.value?.arrangements.arrangements.get(i.arrangement);
      if (a) {
        is.push({ id: i.arrangement, arrangement: a });
      }
    }
  });
  return is;
});
</script>
