<template>
  <div class="panel">
    <div class="panel-heading">
      <div class="level is-mobile">
        <div class="level-left">{{ props.group.name }}</div>
        <div class="level-right level is-mobile">
          <button class="button is-small" @click="save">Save</button>
          <button class="button is-small" @click="emit('cancel')">
            Cancel
          </button>
        </div>
      </div>
    </div>
    <div>
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>role</th>
            <th>player</th>
            <th>backup</th>
          </tr>
        </thead>
        <draggable
          v-model="newParts"
          tag="tbody"
          item-key="id"
          handle=".drag-handle"
        >
          <template #item="{ element }">
            <tr>
              <td class="drag-handle">{{ element.part.name }}</td>
              <td>
                <MembersSelect
                  :members="props.group.members"
                  :initial="element.part.members"
                  :names="names"
                  @change="(m) => setMembers(element.id, m)"
                ></MembersSelect>
              </td>
              <td>
                <MembersSelect
                  :members="props.group.members"
                  :initial="element.part.backups"
                  :names="names"
                  @change="(m) => setBackups(element.id, m)"
                ></MembersSelect>
              </td>
            </tr>
          </template>
          ></draggable
        >
        <tfoot>
          <tr>
            <td>Admins</td>
            <td colspan="2">
              <MembersSelect
                :members="props.group.members"
                :initial="props.group.admins"
                :keep="user ? [user.username] : []"
                :names="names"
                @change="(m) => setAdmins(m)"
              ></MembersSelect>
            </td>
          </tr>
          <tr>
            <td>
              <input v-model="newRole" type="text" class="input" />
            </td>
            <td>
              <button
                class="button"
                :disabled="newRole === ''"
                @click="addRole"
              >
                Add role
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Ref, ref } from "vue";
import draggable from "vuedraggable";
import { user } from "../user";
import { Group, Part } from "../../shared/group";
import MembersSelect from "./MembersSelect.vue";
import { createId, PartId, UserId } from "../../shared/ids";

const props = defineProps<{
  group: Group;
  names: Record<string, string>;
}>();

const emit = defineEmits<{
  (e: "save", group: Group): void;
  (e: "cancel"): void;
}>();
interface NewPart {
  id: PartId;
  part: Part;
}
function initNewPart(): NewPart[] {
  const n: NewPart[] = [];
  for (const [id, part] of props.group.parts) {
    n.push({ id, part });
  }
  return n;
}
const newParts: Ref<NewPart[]> = ref(initNewPart());
const newAdmins = ref([...props.group.admins]);
const newRole = ref("");
function setMembers(id: PartId, members: UserId[]) {
  const part = newParts.value.find((p) => p.id === id);
  if (part) {
    part.part.members = members;
  }
}
function setBackups(id: PartId, backups: UserId[]) {
  const part = newParts.value.find((p) => p.id === id);
  if (part) {
    part.part.backups = backups;
  }
}
function setAdmins(admins: UserId[]) {
  newAdmins.value = admins;
}
function save() {
  const parts = new Map<PartId, Part>();
  for (const p of newParts.value) {
    parts.set(p.id, p.part);
  }
  const newGroup = { ...props.group, parts, admins: newAdmins.value };
  emit("save", newGroup);
}
function addRole() {
  const part = { name: newRole.value, members: [], backups: [] };
  newParts.value.push({ id: createId<PartId>(), part });
  newRole.value = "";
}
</script>
