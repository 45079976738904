<template>
  <nav class="menu navbar">
    <div class="navbar-brand">
      <span
        role="icon"
        class="navbar-burger"
        :class="{ 'is-active': showMenu }"
        aria-label="menu"
        :aria-expanded="showMenu"
        @click="toggleMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </span>
    </div>
    <div
      v-if="showMenu"
      class="navbar-menu is-shadowless"
      :class="{ 'is-active': showMenu }"
    >
      <div class="navbar-start">
        <div class="navbar-item">
          <button class="button is-small" @click="rotate">
            <span class="icon" title="rotate"
              ><i class="fa-solid fa-rotate"></i
            ></span>
          </button>
        </div>
        <div class="navbar-item">
          <button
            class="button is-small"
            :class="{
              'is-inverted': pageFit === 'fit-width',
            }"
            @click="fitToWidth"
          >
            <span class="icon" title="fit-to-width"
              ><i class="fa-solid fa-arrows-left-right"></i
            ></span>
          </button>
        </div>
        <div class="navbar-item">
          <button
            class="button is-small"
            :class="{
              'is-inverted': pageFit === 'fit-height',
            }"
            @click="fitToHeight"
          >
            <span class="icon" title="fit-to-height"
              ><i class="fa-solid fa-arrows-up-down"></i
            ></span>
          </button>
        </div>
        <div class="navbar-item">
          <button
            class="button is-small"
            :class="{
              'is-inverted': mode.isDraw,
            }"
            @click="toggleDrawing"
          >
            <span class="icon" title="drawing"
              ><i class="fa-solid fa-pencil"></i
            ></span>
          </button>
        </div>
        <div class="navbar-item">
          <button
            class="button is-small"
            :class="{
              'is-inverted': mode.isEdit,
            }"
            @click="toggleEditing"
          >
            <span class="icon" title="editing"
              ><i class="fa-solid fa-arrow-pointer"></i
            ></span>
          </button>
        </div>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <button class="button is-small" @click="emit('download')">
            <span class="icon" title="download"
              ><i class="fa-solid fa-download"></i
            ></span>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
<script setup lang="ts">
import { ref, toRef } from "vue";
import {
  type PdfViewOptions,
  viewMode,
  drawMode,
  editMode,
} from "./PdfViewOptions";
const props = defineProps<{
  options: PdfViewOptions;
}>();
const emit = defineEmits<{
  (e: "change", options: PdfViewOptions): void;
  (e: "download"): void;
}>();
const showMenu = ref(false);
const mode = toRef(() => props.options.mode);
const rotation = toRef(() => props.options.rotation);
const pageFit = toRef(() => props.options.pageFit);
/*
watch(options, () => {
  emit("change", options.value);
});
*/
function toggleMenu() {
  showMenu.value = !showMenu.value;
  if (!showMenu.value) {
    emit("change", {
      ...props.options,
      mode: viewMode,
    });
  }
}

function rotate() {
  emit("change", { ...props.options, rotation: (rotation.value + 90) % 360 });
}

function fitToWidth() {
  emit("change", { ...props.options, pageFit: "fit-width" });
}
function fitToHeight() {
  emit("change", { ...props.options, pageFit: "fit-height" });
}

function toggleDrawing() {
  if (mode.value.isDraw) {
    emit("change", { ...props.options, mode: viewMode });
  } else {
    emit("change", {
      ...props.options,
      mode: drawMode,
    });
  }
}
function toggleEditing() {
  if (mode.value.isEdit) {
    emit("change", { ...props.options, mode: viewMode });
  } else {
    emit("change", {
      ...props.options,
      mode: editMode(),
    });
  }
}
</script>
<style>
/*
  Make sure the navbar-burger stays visible as a vertical column of buttons
  even on displays wider than 1028px by using a more specific selector. */
span.navbar-burger {
  display: inline-flex;
}
div.navbar-menu {
  display: block;
}
div.navbar-start {
  display: block;
}
</style>
