<template>
  <div class="column">
    <router-link
      v-if="nextConcert"
      :to="{
        name: 'concert',
        params: { group: group, concert: nextConcert[0] },
      }"
      class="panel-block"
    >
      Concert: {{ localeDateTime(nextConcert[1].start) }}
    </router-link>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { localeDateTime, upcoming } from "../../shared/agenda";
import { getGroupData } from "../store";
import { GroupId } from "../../shared/ids";

const props = defineProps<{
  group: GroupId;
}>();
const data = getGroupData(props.group);

const nextConcert = computed(() =>
  data.value ? [...upcoming(data.value.agenda.concerts)][0] : undefined,
);
</script>
