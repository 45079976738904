import { readonly, Ref, ref } from "vue";
import { logError } from "./errors";

interface ServiceWorkerInfo {
  scriptURL: string;
  state: string;
}

interface ServiceWorkerStatus {
  lastMessage: string | undefined;
  ready: boolean;
  error: string | undefined;
  active: ServiceWorkerInfo | undefined;
  installing: ServiceWorkerInfo | undefined;
  waiting: ServiceWorkerInfo | undefined;
}

const mutableStatus: Ref<ServiceWorkerStatus> = ref({
  lastMessage: undefined,
  ready: false,
  error: undefined,
  active: undefined,
  installing: undefined,
  waiting: undefined,
});
export const status = readonly(mutableStatus);

function messageHandler(this: ServiceWorkerContainer, ev: MessageEvent) {
  console.log("message", ev);
  mutableStatus.value.lastMessage = JSON.stringify(ev.data);
}

function messageerrorHandler(this: ServiceWorkerContainer, ev: MessageEvent) {
  console.log("messageerror", ev);
}

function controllerchangeHandler(this: ServiceWorkerContainer, ev: Event) {
  console.log("controllerchange", ev);
  mutableStatus.value.active = getServiceWorkerInfo(
    navigator.serviceWorker.controller,
  );
}

function getServiceWorkerInfo(
  sw: ServiceWorker | null,
): ServiceWorkerInfo | undefined {
  if (sw) {
    return { scriptURL: sw.scriptURL, state: sw.state };
  }
  return;
}

function setupServiceWorkerRegistrationEvents(
  registration: ServiceWorkerRegistration,
) {
  console.log("setting up service worker registration events", registration);
  mutableStatus.value.active = getServiceWorkerInfo(registration.active);
  mutableStatus.value.waiting = getServiceWorkerInfo(registration.waiting);
  mutableStatus.value.installing = getServiceWorkerInfo(
    registration.installing,
  );
}

function setupServiceWorkerEvents() {
  const sw = navigator.serviceWorker;
  console.log("setting up service worker events", sw);
  sw.addEventListener("message", messageHandler);
  sw.addEventListener("messageerror", messageerrorHandler);
  sw.addEventListener("controllerchange", controllerchangeHandler);
  sw.ready
    .then((registration) => {
      setupServiceWorkerRegistrationEvents(registration);
      mutableStatus.value.ready = true;
      return registration;
    })
    .catch((e: unknown) => {
      if (e instanceof Error) {
        mutableStatus.value.error = e.message;
      }
      logError(e);
    });
}
setupServiceWorkerEvents();
