import { Type, StaticDecode } from "@sinclair/typebox";
import { create_io } from "./ajv.js";
import { sha256Schema } from "./ids.js";

export const welcomeMessageScheme = Type.Object({
  type: Type.Literal("welcome"),
});

export const updateMessageScheme = Type.Object({
  type: Type.Literal("update"),
  rehorseJsonSha256: Type.Record(Type.String(), sha256Schema),
});

export const groupUpdateMessageScheme = Type.Object({
  type: Type.Literal("group-update"),
});

export const serverMessageSchema = Type.Union([
  welcomeMessageScheme,
  updateMessageScheme,
  groupUpdateMessageScheme,
]);

export const server_msg = create_io(serverMessageSchema);

export type UpdateMessage = StaticDecode<typeof updateMessageScheme>;

export type ServerMessage = StaticDecode<typeof serverMessageSchema>;
