<template>
  <Top />
  <div class="panel">
    <div class="panel-heading">
      <h2 class="title">App info</h2>
    </div>
    <div class="column">
      <div class="name-value">
        <div>this page:</div>
        <div>{{ loc }}</div>
        <div>home page:</div>
        <div>
          <a href="https://codeberg.org/vandenoever/rehorse/" target="_blank"
            >https://codeberg.org/vandenoever/rehorse/</a
          >
        </div>
        <div>client version:</div>
        <div>
          <a
            :href="
              'https://codeberg.org/vandenoever/rehorse/releases/tag/v' +
              version
            "
            target="_blank"
            >{{ version }}</a
          >
          ({{ lastModified.replace("T", " ").substring(0, 16) }},
          <a
            :href="
              'https://codeberg.org/vandenoever/rehorse/commit/' + revision
            "
            target="_blank"
          >
            {{ revision.substring(0, 6) }}</a
          >)
        </div>
        <div v-if="serverInfo">server version:</div>
        <div v-if="serverInfo">
          <a
            :href="
              'https://codeberg.org/vandenoever/rehorse/releases/tag/v' +
              serverInfo.version
            "
            target="_blank"
            >{{ serverInfo.version }}</a
          >
          ({{ serverInfo.lastModified.replace("T", " ").substring(0, 16) }},
          <a
            :href="
              'https://codeberg.org/vandenoever/rehorse/commit/' + revision
            "
            target="_blank"
          >
            {{ serverInfo.revision.substring(0, 6) }}</a
          >)
        </div>
        <div>online:</div>
        <div>{{ online }}</div>
        <div>websocket connected:</div>
        <div>{{ connected }}</div>
        <div>
          <a
            href="https://vite-pwa-org.netlify.app/frameworks/vue.html#prompt-for-update"
            target="_blank"
            >offlineReady</a
          >:
        </div>
        <div>{{ offlineReady }}</div>
        <div>
          <a
            href="https://vite-pwa-org.netlify.app/frameworks/vue.html#prompt-for-update"
            taget="_blank"
            >needRefresh</a
          >:
        </div>
        <div>{{ needRefresh }}</div>
      </div>
      <div>
        <button class="button" @click="updateApp">updateApp</button>
      </div>
      <div>
        <button class="button" @click="updateServiceWorker(true)">
          updateServiceWorker(reloadPage:true)
        </button>
      </div>
      <div>
        <button class="button" @click="updateServiceWorker(false)">
          updateServiceWorker(reloadPage:false)
        </button>
      </div>
    </div>
  </div>
  <div class="block">
    <h3 class="heading">Service Worker</h3>
    <div class="column">
      <div class="name-value">
        <div>last message:</div>
        <div>{{ status.lastMessage }}</div>
        <div>ready:</div>
        <div>{{ status.ready }}</div>
        <div>error:</div>
        <div>{{ status.error }}</div>
        <div>active scriptURL:</div>
        <div>{{ status.active?.scriptURL }}</div>
        <div>active state:</div>
        <div>{{ status.active?.state }}</div>
        <div>installing scriptURL:</div>
        <div>{{ status.installing?.scriptURL }}</div>
        <div>installing state:</div>
        <div>{{ status.installing?.state }}</div>
        <div>waiting scriptURL:</div>
        <div>{{ status.waiting?.scriptURL }}</div>
        <div>waiting state:</div>
        <div>{{ status.waiting?.state }}</div>
      </div>
    </div>
  </div>
  <div class="block">
    <router-link :to="{ name: 'cache' }" class="button">Cache</router-link>
  </div>
  <div class="panel">
    <div class="panel-heading">Fonts (status: {{ fontsStatus }})</div>
    <table class="table is-narrow is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th>name</th>
          <th>weight</th>
          <th>style</th>
          <th>status</th>
          <th>unicode range</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="font in fonts" :key="font.family + font.weight + font.style">
          <td>
            {{ font.family }}
          </td>
          <td>{{ font.weight }}</td>
          <td>{{ font.style }}</td>
          <td>{{ font.status }}</td>
          <td>{{ font.unicodeRange }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import { version, lastModified, revision } from "../../shared/version.json";
import Top from "../components/Top.vue";
import { online } from "../online";
import { connected } from "../websocket";
import {
  offlineReady,
  needRefresh,
  updateServiceWorker,
  updateApp,
} from "../pwa";
import { status } from "../pwa-info";
import { logError } from "../errors";

interface VersionInfo {
  version: string;
  lastModified: string;
  revision: string;
}

const serverInfo = ref<VersionInfo | undefined>();
const loc = window.location.href;

const getServerVersion = async (): Promise<VersionInfo> => {
  const response = await fetch("api/version");
  const server: unknown = await response.json();
  if (
    typeof server === "object" &&
    server !== null &&
    "version" in server &&
    "lastModified" in server &&
    "revision" in server
  ) {
    const serverVersion = server.version;
    const serverLastModified = server.lastModified;
    const serverRevision = server.revision;
    if (
      typeof serverVersion === "string" &&
      typeof serverLastModified === "string" &&
      typeof serverRevision === "string"
    ) {
      return {
        version: serverVersion,
        lastModified: serverLastModified,
        revision: serverRevision,
      };
    }
  }
  logError(server);
  throw new Error("Could not retrieve server version.");
};
const fontsStatus = document.fonts.status;
const fonts: FontFace[] = [];
document.fonts.forEach((font) => {
  void font.load();
  fonts.push(font);
});
onMounted(async () => {
  serverInfo.value = await getServerVersion();
});
</script>
<style>
div.name-value {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5em;
}
</style>
