// SPDX-FileCopyrightText: 2024 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import { ajvToJsonSchema, create_io } from "./ajv.js";
import { Type, StaticDecode } from "@sinclair/typebox";
import { createIdSchema, PageAnnotationId, uuidSchema } from "./ids.js";

const pointSchema = Type.Object(
  {
    x: Type.Number(),
    y: Type.Number(),
  },
  { additionalProperties: false },
);
export type Point = StaticDecode<typeof pointSchema>;

const pageAnnotationIdSchema = createIdSchema<PageAnnotationId>();

const annotationPathSchema = Type.Object(
  {
    type: Type.Literal("path"),
    id: pageAnnotationIdSchema,
    d: Type.Array(Type.Array(pointSchema)),
    color: Type.String(),
  },
  { additionalProperties: false },
);
export type AnnotationPath = StaticDecode<typeof annotationPathSchema>;

const annotationTextSchema = Type.Object(
  {
    type: Type.Literal("text"),
    id: pageAnnotationIdSchema,
    text: Type.String(),
    x: Type.Number(),
    y: Type.Number(),
    color: Type.String(),
  },
  { additionalProperties: false },
);
export type AnnotationText = StaticDecode<typeof annotationTextSchema>;

const annotationSchema = Type.Union([
  annotationPathSchema,
  annotationTextSchema,
]);
export type AnnotationItem = StaticDecode<typeof annotationSchema>;

const pageAnnotationsSchema = Type.Object(
  {
    items: Type.Array(annotationSchema),
  },
  { additionalProperties: false },
);
export type PageAnnotations = StaticDecode<typeof pageAnnotationsSchema>;

const partAnnotationsSchema = Type.Object(
  {
    pageAnnotations: Type.Array(pageAnnotationsSchema),
  },
  {
    additionalProperties: false,
  },
);
const partAnnotationsAjvSchema = {
  ...partAnnotationsSchema,
  $defs: { uuid: uuidSchema },
};
export type PartAnnotations = StaticDecode<typeof partAnnotationsSchema>;

export const annotationsIO = create_io(partAnnotationsAjvSchema, [uuidSchema]);

export const annotationsJsonSchema = () =>
  ajvToJsonSchema(
    {
      $schema: "http://json-schema.org/draft-07/schema#",
      $id: "https://rehorse.vandenoever.info/rehorse-annotations.schema.json",
      title: "Rehorse annotations",
      description: "The Rehorse page annotations",
    },
    partAnnotationsAjvSchema,
  );
