import { ref } from "vue";
import { user } from "./user";
import { ErrorObject, serializeError } from "serialize-error";

interface ErrorLogEntry {
  error: Error;
  date: Date;
}

async function sendError(json: string) {
  if (!user.value) {
    return;
  }
  await fetch("api/log-error", {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: json,
  });
}

function getStack(): string | undefined {
  try {
    throw new Error();
  } catch (e) {
    if (e instanceof Error) {
      return e.stack;
    }
  }
  return;
}

export const logError = (...args: unknown[]) => {
  const mappedArgs: (ErrorObject | string)[] = args.map((a) =>
    serializeError(a),
  );
  // if none of the arguments has a stack, get one from this location
  const argsHaveAStack = args.some((arg) =>
    arg instanceof Error ? arg.stack : undefined,
  );
  if (!argsHaveAStack) {
    const stack = getStack();
    if (stack) {
      mappedArgs.push(stack);
    }
  }
  const json = JSON.stringify(mappedArgs);
  errors.value.push({ error: new Error(json), date: new Date() });
  void sendError(json);
};
export const errors = ref<ErrorLogEntry[]>([]);
