<template>
  <Top />
  <div v-if="groups.isReady" class="panel">
    <template v-for="group in groups.data" :key="group.groupname">
      <router-link
        :to="{ name: 'group', params: { group: group.groupname } }"
        class="panel-block"
      >
        {{ group.name }}
      </router-link>
      <GroupSummary :group="group.groupname" />
    </template>
  </div>
  <div v-else-if="groups.isLoading">LOADING</div>
  <div v-else-if="groups.isError">
    {{ groups.error }}
  </div>
</template>
<script setup lang="ts">
import Top from "../components/Top.vue";
import GroupSummary from "../components/GroupSummary.vue";
import { onErrorCaptured } from "vue";
import { groups } from "../loaders/groups";
import { logError } from "../errors";

onErrorCaptured((err: unknown, _instance, info: string) => {
  logError(err, info);
});
</script>
