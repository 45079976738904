<template>
  <div class="range-selector">
    <div>
      <div>begin</div>
      <div>einde</div>
    </div>
    <div v-for="[n, mark] of marks.slice(0, -1).entries()" :key="n">
      <div :class="rangeClass(n)" @click="emit('rangeStart', n)">
        {{ mark.name }}
      </div>
      <div :class="rangeClass(n)" @click="emit('rangeEnd', n)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Mark } from "../../shared/rehorse";

const props = defineProps<{
  marks: Mark[];
  rangeStart: number;
  rangeEnd: number;
}>();

const emit = defineEmits<(e: "rangeStart" | "rangeEnd", pos: number) => void>();

function rangeClass(n: number) {
  if (props.rangeStart <= n && n <= props.rangeEnd) {
    return "inrange";
  } else {
    return "outrange";
  }
}
</script>
