<template>
  <Top />
  <div class="panel">
    <MusicEventEdit
      v-if="edit"
      :group="props.group"
      :event="concertData"
      @change="change"
      @cancel="cancel"
    >
    </MusicEventEdit>
    <template v-else-if="concertData">
      <div class="panel-heading">
        <div class="level is-mobile">
          <div class="level-left" :class="{ cancelled: concertData.cancelled }">
            {{ localeDate(concertData.start) }}
          </div>
          <div class="level-right">
            <button
              v-if="store.canEdit(props.group)"
              class="button is-small"
              @click="edit = true"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      <div class="column">
        <dl class="name-value-grid">
          <dt>Start</dt>
          <dd>{{ localeTime(concertData.start) }}</dd>
          <dt>End</dt>
          <dd>{{ localeTime(concertData.end) }}</dd>
          <dt>Location</dt>
          <dd>{{ location() }}</dd>
          <dt>Remarks</dt>
          <dd>
            <p
              v-for="(p, index) in concertData.remarks.split('\n')"
              :key="index"
            >
              {{ p }}
            </p>
          </dd>
        </dl>
      </div>
      <div class="panel-heading">
        <div class="level is-mobile">
          <div class="level-left">Playlist</div>
          <div class="level-right">
            <PlaylistDownload
              :group="props.group"
              :playlist="concertData.playlist"
            />
          </div>
        </div>
      </div>
      <Playlist :group="props.group" :playlist="concertData.playlist" />
    </template>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useRehorseStore } from "../store";
import Top from "../components/Top.vue";
import MusicEventEdit from "../components/MusicEventEdit.vue";
import Playlist from "../components/Playlist.vue";
import PlaylistDownload from "../components/PlaylistDownload.vue";
import { localeDate, localeTime } from "../../shared/agenda";
import type { Concert } from "../../shared/agenda";
import type { ConcertId } from "../../shared/ids";

const props = defineProps<{
  group: string;
  concert: ConcertId;
}>();
const store = useRehorseStore();
const router = useRouter();
const agenda = store.getAgenda(props.group);
let concertData = agenda.value.concerts.get(props.concert) ?? null;
const edit = ref(!concertData);
function location(): string {
  if (concertData) {
    return agenda.value.locations.get(concertData.location)?.name ?? "";
  }
  return "";
}
async function change(newConcert: Concert) {
  agenda.value.concerts.set(props.concert, newConcert);
  await store.save(props.group);
  concertData = newConcert;
  edit.value = false;
}
function cancel() {
  if (concertData) {
    edit.value = false;
  } else {
    router.go(-1);
  }
}
</script>
