import { readonly, ref, watch } from "vue";
import { online } from "./online";
import { server_msg, ServerMessage } from "../shared/websocket";
import { reloadGroups } from "./loaders/groups";
import { logError } from "./errors";

function createUrl(): string {
  const loc = window.location;
  const protocol = loc.protocol === "https:" ? "wss:" : "ws:";
  return `${protocol}//${loc.host}${loc.pathname}ws`;
}

function createSocket(): WebSocket {
  const s = new WebSocket(url);
  hookup(s);
  return s;
}

const url = createUrl();
let socket = createSocket();
const connected = ref(false);

function close(ws: WebSocket) {
  ws.close();
  ws.onerror = ws.onopen = ws.onclose = null;
}

watch(online, (value) => {
  if (value) {
    reconnect();
  } else {
    close(socket);
  }
});

function reconnect() {
  console.info("reconnecting web socket");
  close(socket);
  if (online.value) {
    socket = createSocket();
  }
}

const mutableRehorseJsonSha256s = ref(new Map<string, string>());
export const rehorseJsonSha256s = readonly(mutableRehorseJsonSha256s);

function handleMessage(msg: ServerMessage) {
  if (msg.type === "update") {
    console.info("get ws message", msg);
    const map = new Map();
    for (const key in msg.rehorseJsonSha256) {
      map.set(key, msg.rehorseJsonSha256[key]);
    }
    mutableRehorseJsonSha256s.value = map;
  } else if (msg.type === "group-update") {
    console.info("there's a group update");
    reloadGroups();
  }
}

function hookup(s: WebSocket) {
  s.onopen = (e: Event) => {
    console.info("WebSocket opened.", e);
    connected.value = true;
  };
  s.onclose = (e: CloseEvent) => {
    console.info("WebSocket closed.", e);
    connected.value = false;
    setTimeout(() => {
      reconnect();
    }, 1000);
  };
  s.onerror = (e: Event) => {
    logError("WebSocket gave an error.", e);
    close(s);
  };
  s.onmessage = (msg) => {
    if (typeof msg.data !== "string") {
      return;
    }
    const jsonResult = server_msg.decode(JSON.parse(msg.data));
    if (jsonResult.isOk) {
      handleMessage(jsonResult.value);
    }
  };
}

export { connected };
