import Dexie, { type Table } from "dexie";
import { Sha256 } from "@aws-crypto/sha256-browser";
import { computed, ComputedRef, ref, Ref } from "vue";
import { RehorseStore, useRehorseStore } from "./store";
import {
  ArrangementId,
  isId,
  isSha256,
  Sha256 as Sha256Type,
  PartId,
  PieceId,
} from "../shared/ids";
import { logError } from "./errors";

interface ShallowCachedFile {
  url: string;
  date: Date;
  size: number;
}

export interface CachedFile extends ShallowCachedFile {
  sha256: string;
  blob: Blob;
}

export type Type = "audio" | "pdf";

interface TitledCachedFile extends ShallowCachedFile {
  title: string | undefined;
  group: string | undefined;
  type: Type | undefined;
}

const cachedb = new Dexie("CacheDatabase") as Dexie & {
  files: Table<CachedFile, string>;
};

// Schema declaration lists the properties that are indexed.
// Do not index large properties.
cachedb.version(1).stores({
  files: "url, date, size, sha256",
});

export const hashValue = async (val: Blob): Promise<Sha256Type> => {
  const hash = new Sha256();
  hash.update(await val.arrayBuffer());
  const hashBuffer = await hash.digest();
  const hashArray = Array.from(hashBuffer);
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  if (isSha256(hashHex)) {
    return hashHex;
  }
  throw new Error("Sha256 is invalid");
};

const deleteFromCache = async (url: string) => {
  try {
    await cachedb.files.delete(url);
  } catch (e: unknown) {
    logError(e);
  }
};

const cacheFile = async (url: string, data: Blob) => {
  const sha256 = await hashValue(data);
  await cachedb.transaction("rw", cachedb.files, async () => {
    const oldData = await cachedb.files.get(url);
    if (oldData?.sha256 === sha256) {
      return;
    }
    if (oldData) {
      await deleteFromCache(url);
    }
    const entry = {
      url,
      date: new Date(),
      size: data.size,
      blob: data,
      sha256,
    };
    await cachedb.files.add(entry, url);
  });
};

const getFileFromCache = async (
  url: string,
): Promise<CachedFile | undefined> => {
  return cachedb.files.get(url);
};

export type Order = "url" | "date" | "size";

export type TitledOrder = Order | "title" | "type";

const cacheFileList = ref<ShallowCachedFile[]>([]);
const updateFilesList = async (): Promise<void> => {
  const l: ShallowCachedFile[] = [];
  await cachedb.files.each((v) => {
    l.push({ url: v.url, size: v.size, date: v.date });
  });
  cacheFileList.value = l;
};

setTimeout(() => void updateFilesList(), 1);
Dexie.on("storagemutated", updateFilesList);

const listCacheFiles = async (order: Order): Promise<ShallowCachedFile[]> => {
  return cachedb.files.orderBy(order).toArray();
};

function addTitleAndType(
  store: RehorseStore,
  item: ShallowCachedFile,
): TitledCachedFile {
  const parts = item.url.split("/");
  let title: string | undefined;
  let type: Type | undefined;
  const group = parts[1];
  if (parts[0] === "groups" && group !== undefined) {
    if (
      parts[2] === "audio" &&
      parts[3] !== undefined &&
      isSha256<PieceId>(parts[3])
    ) {
      type = "audio";
      const pieces = store.getPieces(group);
      try {
        title = pieces.value.get(parts[3])?.title;
      } catch (_e) {
        // ignore
      }
    } else if (
      parts[2] === "arrangement" &&
      parts[3] !== undefined &&
      isId<ArrangementId>(parts[3]) &&
      parts[4] === "part" &&
      parts[5] !== undefined &&
      isId<PartId>(parts[5])
    ) {
      type = "pdf";
      try {
        const arrangements = store.getArrangements(group).value.arrangements;
        title = arrangements.get(parts[3])?.title;
      } catch (_e) {
        // ignore
      }
      try {
        const partName = store.getGroup(group).parts.get(parts[5]);
        if (title && partName) {
          title += " - " + partName.name;
        }
      } catch (_e) {
        // ignore
      }
    }
  }
  return { title, type, group, ...item };
}

const liveCacheFiles = (
  order: Ref<TitledOrder>,
): ComputedRef<TitledCachedFile[]> => {
  return computed(() => {
    let sorter: (a: TitledCachedFile, b: TitledCachedFile) => number = (
      a,
      b,
    ) => (a.url > b.url ? 1 : b.url > a.url ? -1 : 0);
    if (order.value === "title") {
      sorter = (a, b) => (a.title ?? a.url).localeCompare(b.title ?? b.url);
    }
    if (order.value === "date") {
      sorter = (a, b) => a.date.getTime() - b.date.getTime();
    }
    if (order.value === "size") {
      sorter = (a, b) => a.size - b.size;
    }
    if (order.value === "type") {
      sorter = (a, b) => (a.type ?? "").localeCompare(b.type ?? "");
    }
    const newArray: TitledCachedFile[] = [];
    const store = useRehorseStore();
    for (const item of cacheFileList.value) {
      newArray.push(addTitleAndType(store, item));
    }
    newArray.sort(sorter);
    return newArray;
  });
};

const clearCache = async () => {
  return cachedb.files.clear();
};

export {
  cacheFile,
  getFileFromCache,
  listCacheFiles,
  liveCacheFiles,
  clearCache,
  deleteFromCache,
};
