<template>
  <div class="level">
    <div class="file is-normal">
      <label class="file-label">
        <input
          ref="fileInput"
          class="file-input"
          :disabled="uploading"
          type="file"
          multiple="true"
          :accept="accept"
          @change="change"
        />
        <span class="file-cta">
          <span class="file-icon">
            <i class="fas fa-upload"></i>
          </span>
          <span class="file-label">Choose {{ type }} files…</span>
        </span></label
      >
    </div>
    <div class="field">
      <AsyncButton :disabled="!fileReady" :handler="upload">Upload</AsyncButton>
    </div>
  </div>
  <template v-if="uploading">
    <div>uploading...</div>
    <progress class="progress" :value="progress" max="100">
      {{ progress }}
    </progress>
  </template>
  <div v-if="error">{{ error }}</div>
</template>
<script setup lang="ts">
import { ref, Ref } from "vue";
import axios from "axios";
import AsyncButton from "./AsyncButton.vue";

const props = defineProps<{
  type: string;
  accept: string;
  postUrl: string;
}>();
const emit =
  defineEmits<
    (e: "uploaded", index: number, total: number, response: unknown) => void
  >();

const fileInput: Ref<HTMLInputElement | undefined> = ref();
const error = ref("");
const uploading = ref(false);
const progress = ref(0);
const fileReady = ref(false);

function change() {
  const files = fileInput.value?.files;
  fileReady.value = files?.[0] != null;
}

async function upload() {
  error.value = "";
  if (!fileInput.value) {
    error.value = "No file input is found.";
    return;
  }
  if (!fileInput.value.files) {
    return;
  }
  uploading.value = true;
  const files = Array.from(fileInput.value.files);
  let i = 0;
  for (const file of files) {
    const quotedString = file.name
      .replace(/[\u{0080}-\u{FFFF}]/gu, "")
      .replaceAll('"', '\\"');
    try {
      const response = await axios.request({
        method: "post",
        url: props.postUrl,
        data: file,
        headers: {
          "Content-Disposition": `attachment; filename="${quotedString}"`,
        },
        onUploadProgress: (p) => {
          let percent = 100 * i;
          if (p.total) {
            percent += (100 * p.loaded) / p.total;
          } else {
            progress.value = 0;
          }
          progress.value = Math.round(percent / files.length);
        },
      });
      emit("uploaded", i, files.length, response.data);
    } catch (err) {
      if (err instanceof Error) {
        error.value = err.toString();
      }
      break;
    }
    ++i;
  }
  progress.value = 100;
  // clear the <input/> after the files have been uploaded
  fileInput.value.value = "";
  uploading.value = false;
}
</script>
