import { createLoader } from "../loader";
import { Group, groups_io } from "../../shared/group";
import { cachedLoad, Options } from "./cached-loader";
import { computed, ref } from "vue";

const gL = async (uri: string, callback?: (t: Group[]) => void) => {
  const options: Options<Group[]> = { uri, checkForUpdate: true };
  if (callback) {
    options.updateCallback = callback;
  }
  return await cachedLoad(options, async (blob) => {
    const json: unknown = JSON.parse(await blob.text());
    const jsonResult = groups_io.decode(json);
    if (jsonResult.isErr) {
      throw jsonResult.value;
    }
    return jsonResult.value;
  });
};

const groupListLoader = createLoader<Group[]>({ getter: gL });
export const groupsHandle = groupListLoader.get(ref("api/groups"));
export const groups = computed(() => groupsHandle.value.status.value);
export function reloadGroups() {
  groupsHandle.value.release();
  const newGroupsHandle = groupListLoader.get(ref("api/groups"));
  groupsHandle.value = newGroupsHandle.value;
}
