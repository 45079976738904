// SPDX-FileCopyrightText: 2024 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import { groupsData, useRehorseStore } from "./store";
import { Part, Group } from "../shared/group";
import { Arrangement, ArrangementPart } from "../shared/rehorse";
import { ArrangementId, PartId, PlaylistId } from "../shared/ids";
import { pdfLoaderOptions } from "./loaders/pdf";
import { currentDateTime } from "../shared/agenda";
import { listCacheFiles, Type } from "./cachedb";
import { online } from "./online";
import { user } from "./user";
import { audioLoaderOptions, getAudioUri } from "./loaders/audio";
import { DeepReadonly, ref } from "vue";

export interface APart {
  partId: PartId;
  part: Part;
  a?: DeepReadonly<ArrangementPart>[] | null;
}

export const getUserParts = (
  username: string | undefined,
  arrangement: DeepReadonly<Arrangement> | undefined,
  group: DeepReadonly<Group>,
): DeepReadonly<APart>[] => {
  const userparts: DeepReadonly<APart>[] = [];
  for (const [partId, part] of group.parts.entries()) {
    if (part.members.some((m) => m === username)) {
      const a = arrangement?.parts.get(partId) ?? null;
      userparts.push({ partId, part, a });
    }
  }
  for (const [partId, part] of group.parts.entries()) {
    if (part.backups.some((m) => m === username)) {
      const a = arrangement?.parts.get(partId) ?? null;
      userparts.push({ partId, part, a });
    }
  }
  return userparts;
};

export const makePartUrl = (
  group: string,
  arrangement: ArrangementId,
  part: string,
): string => {
  const url = `groups/${group}/arrangement/${arrangement}/part/${part}`;
  return url;
};

interface DesiredArrangement {
  group: DeepReadonly<Group>;
  id: ArrangementId;
  arrangement: DeepReadonly<Arrangement>;
}

const getDesiredArrangements = (): DesiredArrangement[] => {
  const arrs: Readonly<DesiredArrangement>[] = [];
  const store = useRehorseStore();
  const username = user.value?.username;
  if (username === undefined || !store.groups.isReady) {
    return arrs;
  }
  const now = currentDateTime();
  for (const [_groupname, data] of groupsData.value) {
    const playlists = new Set<PlaylistId>();
    for (const [_, concert] of data.agenda.concerts) {
      if (concert.end < now) {
        continue;
      }
      playlists.add(concert.playlist);
    }
    for (const [_, rehearsal] of data.agenda.rehearsals) {
      if (rehearsal.end < now) {
        continue;
      }
      playlists.add(rehearsal.playlist);
    }
    const arrangements = new Set<ArrangementId>();
    for (const playlistId of playlists) {
      for (const arrangement of data.playlists.get(playlistId)?.items ?? []) {
        if (arrangement.arrangement) {
          arrangements.add(arrangement.arrangement);
        }
      }
    }
    for (const arrangementId of arrangements) {
      const arrangement = data.arrangements.arrangements.get(arrangementId);
      if (arrangement) {
        arrs.push({
          group: data.group,
          id: arrangementId,
          arrangement,
        });
      }
    }
  }
  return arrs;
};

const getDesiredPartUrls = (
  arrs: DesiredArrangement[],
): Map<string, string> => {
  const username = user.value?.username;
  const urls = new Map<string, string>();
  for (const arr of arrs) {
    const parts = getUserParts(username, arr.arrangement, arr.group);
    for (const part of parts) {
      if (part.a) {
        const url = makePartUrl(arr.group.groupname, arr.id, part.partId);
        urls.set(url, arr.arrangement.title + " - " + part.part.name);
      }
    }
  }
  return urls;
};

const getDesiredAudioUrls = (
  arrs: DesiredArrangement[],
): Map<string, string> => {
  const store = useRehorseStore();
  const urls = new Map<string, string>();
  for (const arr of arrs) {
    for (const r of arr.arrangement.recordings) {
      const piece = store.getPiece(arr.group.groupname, r);
      urls.set(getAudioUri(arr.group.groupname, r, ref(piece)), piece.title);
    }
  }
  return urls;
};

interface DesiredUrl {
  title: string;
  type: Type;
  getter: (uri: string) => Promise<object>;
}

export const getDesiredUrls = (): Map<string, DesiredUrl> => {
  const arrs = getDesiredArrangements();
  const urls = new Map<string, DesiredUrl>();
  getDesiredPartUrls(arrs).forEach((title, url) => {
    urls.set(url, { title, type: "pdf", getter: pdfLoaderOptions.getter });
  });
  getDesiredAudioUrls(arrs).forEach((title, url) => {
    urls.set(url, { title, type: "audio", getter: audioLoaderOptions.getter });
  });
  return urls;
};

export const retrieveUrl = async (url: string) => {
  await pdfLoaderOptions.getter(url);
};

interface QueueEntry {
  url: string;
  getter: (uri: string) => Promise<object>;
}

const queue: QueueEntry[] = [];
let queueIsRunning = false;

const runQueue = async () => {
  if (queueIsRunning) {
    return;
  }
  queueIsRunning = true;
  let entry = queue.pop();
  while (entry) {
    try {
      await entry.getter(entry.url);
    } catch (_e) {
      //ignore
    }
    entry = queue.pop();
  }
  queueIsRunning = false;
};

export const fillCache = async () => {
  if (!online.value) {
    return;
  }
  const urls = getDesiredUrls();
  const cachedFiles = await listCacheFiles("url");
  for (const cachedFile of cachedFiles) {
    urls.delete(cachedFile.url);
  }
  for (const queuedUrl of queue) {
    urls.delete(queuedUrl.url);
  }
  for (const [url, props] of urls) {
    queue.push({ url, getter: props.getter });
  }
  void runQueue();
};
