<template>
  <button
    :class="['button', ...classes, { 'is-loading': loading }]"
    type="submit"
    :disabled="loading || disabled"
    @click="click"
  >
    <slot></slot>
  </button>
</template>
<script setup lang="ts">
import { ref } from "vue";

const props = defineProps<{
  class?: string;
  disabled: boolean;
  handler(): Promise<void>;
}>();
const classes = props.class?.split(" ") ?? [];
const loading = ref(false);
async function click() {
  loading.value = true;
  try {
    await props.handler();
  } finally {
    loading.value = false;
  }
}
defineExpose({ click });
</script>
