<template>
  <Top />
  <div class="panel">
    <div class="panel-heading">
      <div class="level is-mobile">
        <div class="level-left">Playlists</div>
        <div class="level-right">
          <button
            v-if="store.canEdit(props.group)"
            class="button is-small"
            @click="add"
          >
            Add
          </button>
        </div>
      </div>
    </div>
    <template v-for="[id, pl] of playlists.entries()" :key="id">
      <div v-if="pl.name">
        <router-link
          :to="{ name: 'playlist', params: { playlist: id } }"
          class="panel-block"
        >
          {{ pl.name }}
        </router-link>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { useRouter } from "vue-router";
import { createId, PlaylistId } from "../../shared/ids";
import Top from "../components/Top.vue";
import { useRehorseStore } from "../store";

const props = defineProps<{
  group: string;
}>();

const store = useRehorseStore();
const router = useRouter();
const playlists = store.getPlaylists(props.group);

async function add() {
  const id = createId<PlaylistId>();
  await router.push({
    name: "playlist",
    params: { group: props.group, playlist: id },
  });
}
</script>
