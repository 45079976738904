import { useRegisterSW } from "virtual:pwa-register/vue";
import { logError } from "./errors";
//import { pwaInfo } from "virtual:pwa-info";
//console.log(pwaInfo);

const intervalMS = 60 * 60 * 1000;

interface SWR {
  update(): Promise<void>;
}

let serviceWorkerRegistration: SWR | undefined;

export const updateApp = async () => {
  if (serviceWorkerRegistration) {
    try {
      await serviceWorkerRegistration.update();
    } catch (e: unknown) {
      logError(e);
      alert(e);
    }
  } else {
    alert("There is no ServiceWorkerRegistration");
  }
};

// replaced dynamically
const reloadSW = "__RELOAD_SW__";

export const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW(
  {
    immediate: true,
    onRegisteredSW(swUrl, r) {
      console.log(`Service Worker at: ${swUrl}`);
      serviceWorkerRegistration = r;
      if (reloadSW + "" === "true") {
        if (r) {
          setInterval(() => {
            console.log("Checking for sw update");
            void r.update();
          }, intervalMS);
        }
      } else {
        console.log("SW Registered", r);
      }
    },
    onRegisterError(e: unknown) {
      logError(e);
      alert(e);
    },
  },
);
