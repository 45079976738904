<template>
  <Top />
  <div class="panel">
    <div class="panel-heading">
      <div class="level is-mobile">
        <div class="level-left">Reset password</div>
        <div v-if="showRequestForm" class="level-right">
          <form id="requestReset" onsubmit="return false">
            <AsyncButton
              class="is-small"
              :disabled="!online || !validEmail"
              :handler="send"
              >Send</AsyncButton
            >
          </form>
        </div>
      </div>
    </div>
    <div class="column">
      <div v-if="showRequestForm" class="field">
        <label class="label">Email:</label>
        <div class="control has-icons-left">
          <input
            v-model.trim="email"
            class="input"
            form="requestReset"
            required
            type="email"
            autocomplete="email"
            inputmode="email"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
          </span>
        </div>
        <p v-if="email && !validEmail" class="help is-danger">
          This email is invalid
        </p>
      </div>
      <div v-if="error">
        {{ error }}
      </div>
      <div v-else-if="token">
        <AsyncButton :disabled="!online" :handler="() => reset(token ?? '')"
          >Reset password</AsyncButton
        >
      </div>
      <div v-else-if="sent">A request for a new password has been sent.</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import Top from "../components/Top.vue";
import AsyncButton from "../components/AsyncButton.vue";
import { online } from "../online";
import { EMAIL_RE } from "../../shared/email";
import { useRouter } from "vue-router";
import { logError } from "../errors";

const props = defineProps<{
  token?: string;
}>();

const showRequestForm = computed(
  () => !error.value && !sent.value && !props.token,
);
const router = useRouter();
const email = ref("");
const sent = ref(false);
const error = ref("");
const validEmail = computed(() => EMAIL_RE.test(email.value));
async function send() {
  try {
    const response = await fetch("api/request-password-reset", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ email: email.value }),
    });
    if (response.ok) {
      sent.value = true;
    } else {
      error.value = response.statusText + " " + (await response.text());
    }
  } catch (e) {
    logError(e);
    if (e instanceof Error) {
      error.value = e.toString();
    }
  }
}
async function reset(token: string) {
  try {
    const response = await fetch("api/password-reset", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ token }),
    });
    if (response.ok) {
      sent.value = true;
      await router.push({ name: "user" });
    } else {
      error.value = response.statusText + " " + (await response.text());
    }
  } catch (e) {
    logError(e);
    if (e instanceof Error) {
      error.value = e.toString();
    }
  }
}
</script>
